.contact-main {
    padding: 20px;
    background-color: white;
  }

.contact-header {
    font-size: 42px;
}
  
  .yhteystiedot-contact-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .contact-box {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: calc(50% - 20px); /* Two boxes per row */
    box-sizing: border-box;
  }
  
  .contact-box h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .contact-box p {
    margin: 5px 0;
  }
  
  .contact-box a {
    color: black;
    text-decoration: none;
  }
  
  .contact-box a:hover {
    text-decoration: underline;
  }

  .yhteystiedot-footer {
    margin-top: -5%;
  }
    
  @media only screen and (max-width: 768px) {
    .contact-box {
      width: calc(100% - 20px); /* One box per row */
    }

    .yhteystiedot-footer {
        margin-top: 0%;
      }
  }
  