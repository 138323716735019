/* App.css */
.App {
  font-family: 'Arial', sans-serif;
  background-color: rgb(255, 255, 255); /* Light gray background */
  text-align: center; /* Center all text */
}

.header {
  background-size: cover;
  color: rgb(255, 255, 255);
  padding: 250px 0;
  /*text-shadow: 1px 1px 1px rgb(143, 143, 143); */
  position: relative;
  /* Set a height for the header to ensure the SVG is positioned correctly */
  height: 200px;
  background-position: center;
  top: -1px;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
  /* Position the content at the center */
  position: absolute;
  top: 15%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: left;
  margin-left: 5%;
}

.header-button {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  z-index: 2; 
  display: flex;
  justify-content: center;
  align-items: center;
  left: 75%;
  bottom: 85%;
  width: 8em;
  height: 3em;
  background-color: white;
  color: black;
  font-weight: bold;
}

.header h1 {
  font-size: 3rem;
  color: rgb(223 225 229)
}

.header p {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(223 225 229)
}

.wave {
  position: absolute;
  bottom: -0.7px;
  left: 0;
  width: 100%;
  height: auto;
}

.main-content {
  background-color: rgb(255, 255, 255);
  z-index: 10;
  flex: 1;
}

.company-info-wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Make both sections take the same height */
  height: 40rem;
  border-bottom: 0.5rem solid rgb(0, 0, 66);
}

.company-info-wrapper h2 {
  margin-left: 5%;
}

.company-info, .company-info-2 {
  flex: 1; /* Make both sections take equal width */
}

.company-info {
  background-color: rgb(255, 255, 255); /* White background */
  text-align: left;
  position: relative;
}

.company-info-2 img {
  display: flex;
  height: 50%;
  width: 50%;
  object-fit: cover;
  border-radius: 10px;
}

.image-wrapper {
  max-width: 50%;
  padding-top: 50px;
}

.eristysKuva img {
  position: relative;
  height: 100%;
  width: 60%;
}

.luotettavaKumppani img {
  width: 60%;
  height: 40%;
  object-fit: cover;
  /* position: absolute; */
  /* left: 20%; */
  transform: translate(50%, -30%);
  border-radius: 50px;
}

.company-info {
  background-color: #ffffff;
  padding: 30px;
  text-align: start;
  border-radius: 8px;
}

.company-info h2 {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.company-info li {
  font-size: 1.25rem;
  line-height: 150%;
  margin-bottom: 1rem;
  padding: 0;
}

.company-info strong {
  font-weight: bold;
}



.company-info h2, .company-info p {
  margin-bottom: 20px;
  text-align: left;
  font-family: 'Arial', sans-serif; /* Different font */
  color: #333333; /* Dark gray color */
  display: flex;
}

.contact-info {
  max-width: 100%; /* Take 100% of the space */
  height: 40rem;
  background-color: rgb(255, 255, 255); /* Lighter gray background */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact-info h4 {
  line-height: 1.6;
}

.contact-info h2 {
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif; /* Different font */
  color: #333333; /* Dark gray color */
  font-size: xx-large;
  font-weight: 100;
}

.contact-info-Yhteystiedot {
  text-align: left;
  flex: 1; /* Take up available space */
  margin-right: 20px; /* Add some spacing between sections */
}

.contact-info-Maps {
  text-align: right;
  flex: 1; /* Take up available space */
  padding-right: 50px;
  width: 100%;
  padding-top: 50px;
}

.wave2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(-99%); 
  z-index: 0; 
}

.footer {
  background-color: rgb(0, 0, 66);
  color: white;
  font-size: larger;
  position: relative;
  padding: 50px;
  margin-top: 5%;
  z-index: 1; 
}

.footer-texts {
  text-align: left;
  font-size: 20px;
}

.footer-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit; /* Ensure the link text color matches the surrounding text */
}

.footer-link span {
  margin-right: 8px; /* Adjust spacing between text and image */
}

.footer-lamppu {
  height: 48px; /* Adjust the size of the image as needed */
  width: auto;
  vertical-align: middle; /* Ensure the image aligns properly with text */
}


.contact-button {
  background-color: rgb(0, 0, 66);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  z-index: 2; 
  height: 3em;
}

.contact-info-details {
  flex: 1;
  padding: 20px;
  background-color: white;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 30%;
}

.contact-info h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 1rem;
}

.contact-info address {
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5;
}

.contact-info strong {
  font-weight: bold;
}

.contact-info hr {
  margin: 20px 0;
  border-color: rgb(0, 0, 66);
}

.company-info-mobile,
.contact-info-Yhteystiedot-mobile,
.company-info-2-mobile,
.contact-info-Maps-mobile,
.image-wrapper-mobile,
.contact-info-details-mobile {
  display: none;
}

@media only screen and (max-width: 1150px) {
  .image-wrapper {
    display: flex;
    align-items: center;
  }
  .eristysKuva img {
    transform: translateY( -50%);
    width: 120%;
    height: 50%;
  }
  .luotettavaKumppani img {
    width: 120%;
    height: 25%;
    transform: none;
    position: relative;
    z-index: 1;
    right: 33%;
    top: 33%;
    border-radius: 50px;
    object-fit: contain;
  }

  .luotettavaKumppani {
    width: 80%;
  }
}

@media only screen and (max-width: 850px) { 
  .company-info ul li {
    font-size: medium;
  }
}

@media only screen and (max-width:590px) {

  .company-info-mobile,
.contact-info-Yhteystiedot-mobile,
.company-info-2-mobile,
.contact-info-Maps-mobile,
.contact-info-details-mobile {
  display:contents;
}

  .image-wrapper-mobile {
  display:contents;
  }

  .luotettavaKumppani img {
    right: 0;
  }

  .eristysKuva img {
    transform: translateY(0);
  }
  
  .contact-info,
  .company-info-wrapper,
  .contact-info-details,
  .contact-info-Maps {
    display: none;
  }
  
  .header-content {
    left: 0;
    top: 0;
    margin-right: 2%;
    font-size: 24px;
    text-align: center;
    transform: translate(0,0);
  }

  .header-button {
    left: 30%;
    top: 155%;
    font-size: 1.3em;
  }

  .company-info h2 {
    text-align: center;
  }

  .company-info {
    flex: 1;
  }

  .company-info-mobile {
    margin-bottom: 10%;
    text-align: center;
  }

  .company-info-2-mobile img {
    overflow: auto;
    max-width: 90%;
    max-height: 90%;
    margin-top: 5%;
  }

  .eristysKuva img {
    left: 15%;
    position: relative;
    height: 100%;
    width: 70%;
    margin-top: 20%;
  }
  
  .luotettavaKumppani img {
    width: 60%;
    height: 40%;
    object-fit: cover;
    transform: translate(35%, -30%);
    border-radius: 50px;
    margin-top: 20%;
    margin-bottom: 10%;
  }

  .contact-info-details-mobile {
    line-height: "1.5"
  }

  .wave2 {
    transform: translateY(-99%);
  }

  .footer {
    margin-top: 20%;
  }

  .footer-texts {
    text-align: center;
    font-size: 20px;
  }

}

@media only screen and (min-width:1700px) {
  .company-info-2 img {
    overflow: auto;
    max-width: 45%;
    max-height: 45%;
    margin-top: 5%;
    position: relative;

  }

  .luotettavaKumppani img {
    right: -10%;
    margin-top: -5%;
  }

  .company-info {
    margin-top: -0%;
  }

  .company-info-wrapper {
    margin-bottom: 5%;
    margin-top: -1%;
  }

  .wave {
    margin-bottom: -0.1%;
  }
}