.cookie-consent {
  position: fixed;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  text-align: center;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: 500px;
}

.cookie-consent p {
  margin-bottom: 20px;
  font-size: 18px;
}

.cookie-consent-buttons {
  display: flex;
  justify-content: space-between;
}

.cookie-consent button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  flex: 1;
  margin: 0 10px;
}

.cookie-consent button:hover {
  background-color: #c0392b;
}
